<template>
    <div class="menu-search" :class="{ 'menu-search--open' : open, 'menu-search--footer' : isFooter }">
        <input type="text" 
            name="search" 
            class="menu-search__input" 
            v-model="search" 
            :placeholder="$t('components.MenuSearch.search')"
        >
        <span class="material-icons-round menu-search__icon" @click="searchByWord">search</span>
    </div>
</template>

<script>
export default {
    props: {
        open: {
            type: Boolean,
            required: true
        },

        isFooter: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            search: ''
        }
    },

    methods: {
        searchByWord() {
            this.$emit('close')
            this.$router.push({ name: 'Search', query: { word: this.search }})
        },
    }
}
</script>

<style lang="scss" scoped>
    .menu-search {
        $r : '.menu-search';

        position: absolute;
        width: max-content;
        right: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;

        &--open {
            top: 80px;
            transition: .3s;
            opacity: 1;
            visibility: visible;

            @media screen and (max-width: $lg) {
                right: 20px;
            }
        }

        &--footer {
            &#{$r}--open {
                top: -80px;
            }
        }

        &__input {
            border: none;
            border-radius: 2px;
            color: white;
            background-color: fade-out($light, .8);
            padding: 3px 20px;
            font-size: 16px;
            font-family: $ff-primary;
            width: 35vw;
            height: 50px;
            max-width: 600px;
            box-sizing: border-box;

            @media screen and (max-width: $lg) {
                width: calc(100vw - 40px);
                max-width: 100%;
                background-color: #19273d;
            }

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: white
            }
        }

        &__icon {
            position: absolute;
            color: white;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 24px;
            right: 0;
            padding: 10px;
            padding-right: 14px;
            cursor: pointer;

            &:hover {
                color: $yellow;
            }
        }
    }
</style>