<template>
    <div class="section-title">
        <h2 class="typography typography--h2 section-title__title">{{ title }}</h2>
        <ButtonComponent v-if="hasButton" class="section-title__button" @click="$emit('see-more')" :caption="$t('components.SectionTitle.see-more')" />
        <div class="section-title__changeView" v-if="changeView">
            <button
                class="section-title__changeViewButton"
                :class="{ 'section-title__changeViewButton--active' : getResultsView == 'grid' }" 
                type="button"
                @click="changeViewEvent('grid')"
            >
                <span class="material-icons-outlined">grid_view</span>
            </button>
            <button 
                class="section-title__changeViewButton" 
                :class="{ 'section-title__changeViewButton--active' : getResultsView == 'list' }" 
                type="button"
                @click="changeViewEvent('list')"
            >
                <span class="material-icons-outlined">view_day</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        hasButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        changeView: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    computed: {
        ...mapGetters(['getResultsView']),
        isSmView() {
            return this.windowWidth < 575 ? true : false
        }
    },

    watch: {
        isSmView(v) {
            if (v) {
                this.changeViewEvent('grid')
            }
        }
    },

    methods: {
        changeViewEvent(view) {
            this.$store.dispatch('setResultsView', view)
        }
    }
}
</script>

<style lang="scss" scoped>
    .section-title {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;

        &::before {
            position: absolute;
            content: '';
            background-color: $blue;
            height: 1px;
            width: 100%;
            bottom: -15px;
            left: 0;
        }

        &::after {
            position: absolute;
            content: '';
            background-color: $blue;
            height: 10px;
            width: 30vw;
            bottom: -15px;
            left: 0;
        }

        &__title {
            text-transform: uppercase;
            word-break: break-word;

            @media screen and (max-width: $sm) {
                max-width: calc(100% - 150px);
            }
        }

        &__add {
            color: white;
        }

        &__changeView {
            @media screen and (max-width: $sm) {
                display: none;
            }
        }

        &__changeViewButton {

            &:first-child {
                margin-right: 10px;
            }
            
            span {
                font-size: 28px;
                color: $blue;
            }

            &:hover, &--active {
                span {
                    color: $yellow;
                }
            }
        }
    }
</style>