<template>
    <header class="header" :class="{ 'header--open' : menuOpen}">
        <div class="header__burger" @click="toggleBurger" v-if="isMobile">
            <div class="header__burgerLines"><span></span><span></span><span></span></div>
        </div>
        <div class="header__logoWrapper" v-if="hasLogo">
            <img v-bind:src="require('@/assets/img/' + $t('components.Header.logo'))" alt="png" class="header__logo">
        </div>

        <Menu :type="'header'" :open="menuOpen" />

        <div class="header__actions">
            <button v-if="!isSearchPage" class="header__button" type="button" @click="toggleSearch">
                <span class="material-icons-round header__loupe">search</span>
            </button>
            <!-- <button class="header__button" type="button" v-if="false">
                <span class="material-icons-round header__login">login</span>
            </button> -->
            <!-- <button class="header__button" type="button">
                <span class="material-icons-round header__user">person</span>
            </button> -->

            <LanguageSwitcher v-if="isMobile" />
        </div>

        <MenuSearch :open="isOpenSearch" @close="isOpenSearch = false" />
    </header>
</template>

<script>
import Menu from "@/components/Menu"
import MenuSearch from "@/components/MenuSearch"
import LanguageSwitcher from "@/components/LanguageSwitcher";

export default {
    components: {
        Menu,
        MenuSearch,
        LanguageSwitcher
    },

    props: {
        hasLogo: {
            required: false,
            default: true
        }
    },

    data() {
        return {
            isOpenSearch: false,
            menuOpen: false,
        }
    },

    computed: {
        isSearchPage() {
            return this.$route.name == 'Search' ? true : false
        }
    },

    watch: {
        $route() {
            this.menuOpen = false
        }
    },

    methods: {
        toggleSearch() {
            this.isOpenSearch = !this.isOpenSearch
        },

        toggleBurger() {
            let body = document.querySelector('body');
            this.menuOpen ? body.style['overflow-y'] = 'auto' : body.style['overflow-y'] = 'hidden';
            this.menuOpen = !this.menuOpen
        }
    }
}
</script>

<style lang="scss" scoped>
    .header {
        $r: '.header';

        display: flex;
        align-items: center;
        padding-top: 20px;
        position: relative;
        transition: .2s;
        z-index: 1;

        @media screen and (max-width: $xxl) {
            flex-wrap: wrap;
        }

        @media screen and (max-width: $lg) {
            border-bottom: 2px solid $yellow;
            position: fixed;
            width: calc(100% - 40px);
            z-index: 100;
            padding: 10px 20px;
            padding-bottom: 7px;
            align-items: baseline;
            background-color: $darkmenu;
        }

        &__logoWrapper {
            width: 13vw;
            padding: 0;
            padding-left: 10px;

            @media screen and (max-width: $xxl) {
                width: 10vw;
            }

            @media screen and (max-width: $lg) {
                width: 140px;
                display: flex;
                align-self: center;
            }

            @media screen and (max-width: $sm) {
                width: 70px;
                padding-left: 0;
            }
        }

        &__logo {
            width: 100%;
        }

        &__actions {
            margin-left: 16px;

            @media screen and (max-width: $lg) {
                margin-left: auto;
                display: flex;
            }
        }

        &__loupe, &__login, &__user {
            color: white;
            font-size: 18px;
        }

        &__button {
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 3px;

            &:last-child {
                margin-left: 5px;
            }

            &:hover {
                #{$r}__loupe, #{$r}__login, #{$r}__user {
                    color: $yellow;
                }
            }

            @media screen and (max-width: $lg) {
                padding: 3px 10px;
            }
        }

        &__burger {
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            transition: .2s;
        }

        &__burgerLines {
            opacity: 1;
            transition: opacity .3s;

            span {
                display: block;
                width: 25px;
                height: 2px;
                transition: width .3s;
                margin: 4px 0;
                border-radius: 2px;
                background-color: $yellow;
            }
        }

        &--open {
            overflow-y: auto;
            min-height: 100vh;
            background-color: $darkmenu;

            #{$r}__logoWrapper {
                 @media screen and (max-width: $lg) {
                    align-self: flex-start;
                    margin-top: 7px;
                }

                @media screen and (max-width: $sm) {
                    align-self: flex-start;
                    margin-top: 16px;
                }
            }
        }
    }
</style>
