<template>
    <div class="footer">
        <div class="footer__banner container">
            <img class="footer__bannerImg" src="~@/assets/img/main_round.png" alt="png">
            <h2 class="typography typography--h2 footer__bannerTitle">{{ $t('components.Footer.title') }}</h2>
        </div>

        <div class="container">
            <div class="footer__down">
                 <p class="footer__rights">
                    © 2021
                </p>

                <Menu :type="'footer'" :open="false" />

                <div class="footer__actions" v-if="!isMobile">
                    <button v-if="!isSearchPage" class="footer__button" type="button" @click="toggleSearch">
                        <span class="material-icons-round footer__search">search</span>
                    </button>
                    <!-- <button class="footer__button" type="button" v-if="false">
                        <span class="material-icons-round footer__login">login</span>
                    </button> -->
                    <!-- <button class="footer__button" type="button">
                        <span class="material-icons-round footer__user">person</span>
                    </button> -->
                </div>

                <MenuSearch :open="isOpenSearch" :is-footer="true" @close="isOpenSearch = false" />
            </div>
           
        </div>
    </div>
</template>

<script>

import Menu from "@/components/Menu"
import MenuSearch from "@/components/MenuSearch"

export default {
    components: {
        Menu,
        MenuSearch
    },

    data() {
        return {
            isOpenSearch: false
        }
    },

    computed: {
        isSearchPage() {
            return this.$route.name == 'Search' ? true : false
        }
    },

    methods: {
        toggleSearch() {
            this.isOpenSearch = !this.isOpenSearch
        }
    }
}
</script>

<style lang="scss" scoped>
    .footer {
        display: flex;
        flex-direction: column;
        background-color: $dark;
        margin-top: auto;

        &__banner {
            display: flex;
            background: url("~@/assets/img/footer_bg.png") no-repeat center/cover;
            align-items: center;
            padding-top: 30px;
            padding-bottom: 30px;

            @media screen and (max-width: $lg) {
                background: $dark;
                justify-content: center;
            }
        }

        &__bannerImg {
            width: 150px;
            margin-right: 20px;

            @media screen and (max-width: $lg) {
                width: 100px;
            }
        }

        &__bannerTitle {
            color: white;
            font-size: calc(2.5vw + 2.5vh);
            font-family: "Mistral";
            font-weight: normal;

            @media screen and (max-width: $lg) {
                font-size: 30px;
            }
        }

        &__down {
            display: flex;
            align-items: center;
            position: relative;

            @media screen and (max-width: $lg) {
                flex-direction: column-reverse;
            }
        }

        &__actions {
            margin-left: 16px;
            display: flex;
        }

        &__search, &__login, &__user {
            color: white;
            font-size: 18px;

            &:hover {
                color: $yellow;
            }
        }

        &__button {
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 3px;
            display: flex;

            &:last-child {
                margin-left: 5px;
            }
        }

        &__rights {
            color: white;
        }
    }
</style>