<template>
    <div :class="menuClass">
        <localized-link to="/" :class="`${type}__link`">{{ $t('components.Menu.main') }}</localized-link>
        <div
            v-for="item in menuItems" 
            :key="item.id"
            :class="`${type}__link`"
            @click.self="linkClick($event, item.uri)"
        >
            {{ item.label }}
            <div :class="`${type}__sublinkWrapper`" v-if="item.items.length">
                <localized-link 
                    v-for="sub in item.items" 
                    :key="sub.id" 
                    :to="{ name: 'Chapter', params: { name: sub.uri } }" 
                    :class="`${type}__link`"
                    v-if="!isLinkDisable"
                >
                    {{ sub.label }}
                </localized-link>
            </div>
        </div>

        <div class="menu" v-if="isMobile && type == 'header'">
            <!--  <localized-link 
                to="#" 
                class="menu__item"
            >
                {{ $t('components.UpperHeader.login') }}
            </localized-link> -->
            <localized-link
                :to="{ name: 'Feedback' }" 
                class="menu__item" 
            >
                {{ $t('components.UpperHeader.feedback') }}
            </localized-link>
            <localized-link 
                :to="{ name: 'Concept' }"
                class="menu__item"
            >
                {{ $t('components.UpperHeader.concept') }}
            </localized-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        open: {
            required: true,
            default: false,
            type: Boolean
        },
        type: {
            required: false,
            default: 'header', //footer
            type: String
        },
    },

    data() {
        return {
            menuItems: [],
            isLinkDisable: false
        }
    },

    computed: {
        menuClass() {
            let baseClass = `${this.type}__menu`;
            let activeClass = this.open ? `${this.type}__menu--open` : '';
            return [baseClass, activeClass]
        },
    },

    watch: {
        '$i18n.locale': function(newVal) {
            this.getMenu()
        },
        $route() {
            this.isLinkDisable = true;
            setTimeout(() => {
                this.isLinkDisable = false;
            }, 1500);
        }
    },

    mounted() {
        this.getMenu()
    },

    methods: {
        getMenu() {
            this.api.menu.index().then(r => {
                this.menuItems = r
            })
        },

        linkClick(e, uri = null) {
            if (this.isMobile) {
                this.type == 'header' ? e.target.classList.toggle('header__link--active') : e.target.classList.toggle('footer__link--active')
            }
            else {
                this.$router.push({ name: 'Category', params: { uri } }).catch(() => {})
            }
        }
    }
}
</script>

<style lang="scss" scoped>

    /* menu */
    .menu {
        display: flex;
        align-items: center;
        width: calc(100% - 40px);
        justify-content: center;
        margin: 20px 0;
        flex-wrap: wrap;

        &__item {
            color: #717c8a;
            border-right: 1px solid #717c8a;
            padding: 0 10px;

            &:last-child {
                border-right: 0;
            }

            @media screen and (max-width: $lg) {
                margin: 10px 0;
            }
        }
    }

    /* general */
    .header, .footer {
        &__menu {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;

            @media screen and (max-width: $lg) {
                margin-right: auto;
            }
        }

        &__link {
            text-transform: uppercase;
            color: white;
            padding: 0 8px;
            font-size: 16px;
            font-family: 'OpenSans';
            position: relative;
            cursor: pointer;

            @media screen and (max-width: $xxl) {
                font-size: 14px;
            }

            @media screen and (max-width: $xl) {
                font-size: 12px;
                padding: 0 5px;
            }
        }

        &__sublinkWrapper {
            opacity: 0;
            visibility: hidden;
            display: flex;
            z-index: 100;
            background-color: white;
            position: absolute;
            flex-direction: column;
            max-width: 250px;
            min-width: 250px;
            left: 0;
        }
    }

    /* header menu */
    .header {
        $h: '.header';

        &__sublinkWrapper {
            top: auto;

            #{$h}__link {
                color: $black;
                padding: 5px 10px;
                font-size: 14px;

                &::after {
                    display: none;
                }

                &:hover {
                    background-color: $yellow;
                }
            }
        }

        #{$h}__menu > &__link {
            @media screen and (min-width: $lg) {
                &:hover {
                    color: $yellow;

                    #{$h}__sublinkWrapper {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            @media screen and (max-width: $lg) {
                font-size: 18px;
                padding: 0;
                padding-bottom: 25px;
                width: 100%;
                position: relative;

                &:not(:first-child)::after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-top: 3px solid white;
                    border-right: 3px solid white;
                    right: 5px;
                    top: -5px;
                    position: absolute;
                    display: block;
                    transform: translate(0, 50%) rotate(135deg);
                    transition: .3s;
                }

                &--active {
                    #{$h}__sublinkWrapper {
                        position: relative;
                        background: transparent;
                        opacity: 1;
                        visibility: visible;
                        max-width: 100%;

                        #{$h}__link {
                            color: #ddd;
                            padding: 15px 20px;

                            &:first-child {
                                padding-top: 25px;
                            }

                            &:hover {
                                background-color: transparent;
                                color: $yellow;
                            }
                        }
                    }

                    &::after {
                        border-top: 3px solid $yellow !important;
                        border-right: 3px solid $yellow !important;
                        top: -2px !important;
                        transform: translate(0, 50%) rotate(-45deg) !important;
                    }
                }
            }

        }

        &__menu {
            @media screen and (max-width: $lg) {
                position: absolute;
                background: $darkmenu;
                top: 67px;
                display: flex;
                flex-direction: column;
                width: calc(100vw - 40px);
                visibility: hidden;
                left: -120vw;
                transition: .3s;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 20px;
                padding-top: 75px;
                min-height: calc(100vh - 67px - 40px);

                &--open {
                    visibility: visible;
                    left: 0;
                    border-top: 2px solid $yellow;
                }
            }
        }

    }

    /* footer menu */
    .footer {
        $f: '.footer';

        &__link {
            &:hover {
                color: $yellow;
                
                @media screen and (min-width: $lg) {
                    #{$f}__sublinkWrapper {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            @media screen and (max-width: $lg) {
                font-size: 14px;
                padding: 10px 0;
            }
        }

        &__sublinkWrapper {
            bottom: 100%;

            #{$f}__link {
                color: $black;
                padding: 5px 10px;
                font-size: 14px;

                @media screen and (max-width: $sm) {
                    font-size: 12px;
                }

                &:hover {
                    background-color: $yellow;
                }
            }
        }

        &__menu {
            @media screen and (max-width: $lg) {
                flex-direction: column;
            }
        }

        #{$f}__menu > &__link {
            @media screen and (min-width: $lg) {
                &:hover {
                    color: $yellow;

                    #{$f}__sublinkWrapper {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            @media screen and (max-width: $lg) {
                font-size: 18px;
                padding: 0;
                padding-bottom: 25px;
                position: relative;

                &--active {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    #{$f}__sublinkWrapper {
                        position: relative;
                        background: transparent;
                        opacity: 1;
                        visibility: visible;
                        max-width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        #{$f}__link {
                            color: #ddd;
                            padding: 10px 20px;

                            &:first-child {
                                padding-top: 20px;
                            }

                            &:hover {
                                background-color: transparent;
                                color: $yellow;
                            }
                        }
                    }
                }
            }

        }
    }
</style>
